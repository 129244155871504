import Image from 'next/image'
import styles from './FeaturedIn.module.scss'
import { useEffect, useRef, useState } from 'react'

type Company = {
  image: string
  width: number
  height: number
  alt: string
  url: string
  title?: string
}

type CompaniesProps = Company[]

const companies: CompaniesProps = [
  {
    image: '/assets/featuredIn/ih_logo.svg',
    url: 'https://www.indiehackers.com/post/new-domain-ranked-1-on-google-within-two-weeks-heres-my-process-for-finding-easy-to-rank-keywords-fee75faede',
    title:
      "New domain ranked #1 on Google within two weeks: Here's my process for finding easy-to-rank keywords",
    width: 90,
    height: 80,
    alt: 'IH logo',
  },
  {
    image: '/assets/featuredIn/cmi_logo.svg',
    url: 'https://contentmarketinginstitute.com/articles/simplified-keyword-research',
    title:
      'Ask These 4 Questions To Stop Wasting Time on SEO Dead Ends (and Find Keyword Gold)',
    width: 163,
    height: 80,
    alt: 'CMI logo',
  },
  {
    image: '/assets/featuredIn/inc_logo.svg',
    url: 'https://www.inc.com/john-hall/5-technologies-that-can-help-improve-your-business-in-2023.html',
    title:
      'Ask These 4 Questions To Stop Wasting Time on SEO Dead Ends (and Find Keyword Gold)',
    width: 230,
    height: 80,
    alt: 'Inc logo',
  },
]

const FeaturedIn = () => {
  const [isContainerVisible, setIsContainerVisible] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsContainerVisible(true)
            if (containerRef.current) observer.unobserve(containerRef.current)
          }
        })
      },
      { threshold: 0.5 }
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [])

  return (
    <section
      className={
        isContainerVisible
          ? `${styles.container} ${styles.visible}`
          : `${styles.container}`
      }
      ref={containerRef}
    >
      <div className={styles.title}>Featured In</div>
      <div className={styles.imagesContainer}>
        {companies.map((company, index) => (
          <div className={styles.imageWrapper} key={index + company.alt}>
            <a href={company.url} target="_blank" rel="noreferrer noopener">
              <Image
                src={company.image}
                width={company.width}
                height={company.height}
                alt={company.alt}
                className={styles.img}
              />
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}

export default FeaturedIn
