export type TestimonialsProps = {
  image: string
  name: string
  company: any
  testimonial: string
}

export const testimonials: TestimonialsProps[] = [
  {
    image: '/assets/testimonials/OsmanHusain.png',
    name: 'Osman Husain',
    company: (
      <span>
        Head of Growth @{' '}
        <a href="https://www.enzuzo.com/" target="_blank" rel="noreferrer">
          Enzuzo
        </a>{' '}
      </span>
    ),
    testimonial:
      'I find TopicRanker more and more relevant & valuable to find keywords with weak spots in SERPs. The latest Google Algorithm Leak in May 2024 proves how important SERP analysis is in keyword research. Just as an example from the leak: Title mismatch is a huge factor in Google ranking factors.',
  },
  {
    image: '/assets/testimonials/MarissaStGermain.png',
    name: 'Marissa St Germain',
    company: (
      <span>
        Founder @{' '}
        <a
          href="https://www.magnifypt.com/dover-physical-therapist"
          target="_blank"
          rel="noreferrer"
        >
          Dover Physical Therapy
        </a>{' '}
      </span>
    ),
    testimonial:
      'I am a private practice physical therapist, TopicRanker has been a game changer. In just one week I found two SERPs with weak spots, published two landing pages targeting these keywords and I am already ranking high on Google in just days and moving up!',
  },
  {
    image: '/assets/testimonials/JoeManna.png',
    name: 'Joe Manna',
    company: (
      <span>
        Head of SEO @{' '}
        <a
          href="https://www.nextiva.com/products/contact-center-solutions/"
          target="_blank"
          rel="noreferrer"
        >
          Nextiva
        </a>{' '}
      </span>
    ),
    testimonial:
      'TopicRanker is a great tool to help site owners focus NOT on just keyword difficulty scores but  keywords which have an actual weak spot and problem on SERP. It’s ideal for in-house marketers who don’t want the hassle of an expensive or complicated SEO analysis tool.',
  },
  {
    image: '/assets/testimonials/KatVonRohn.png',
    name: 'Kat Von Rohr',
    company: (
      <span>
        Head of content @{' '}
        <a
          href="https://www.salesmessage.com/"
          target="_blank"
          rel="noreferrer"
        >
          SalesMessage
        </a>{' '}
      </span>
    ),
    testimonial:
      'Love TopicRanker! We rank #1 for a number of major keywords solely thanks to TopicRanker. I love that It focuses on the missteps other content creators make and points out the problems that other content has on their pages, so you can leverage those missed opportunities.',
  },
  {
    image: '/assets/testimonials/BrianDean.png',
    name: 'Brian Dean',
    company: (
      <span>
        Founder @{' '}
        <a
          href="https://www.explodingtopics.com"
          target="_blank"
          rel="noreferrer"
        >
          ExplodingTopics.com
        </a>{' '}
        &{' '}
        <a href="https://www.backlinko.com" target="_blank" rel="noreferrer">
          Backlinko.com
        </a>
      </span>
    ),
    testimonial:
      'Very helpful tool for identifying "hidden gem" keywords that are relatively easy to rank for.  TopicRanker is the future of the keyword research.',
  },
  {
    image: '/assets/testimonials/IrinaMaltseva.png',
    name: 'Irina Maltseva',
    company: (
      <span>
        Growth Advisor @{' '}
        <a
          href="https://www.aura.com/identity-theft-protection"
          target="_blank"
          rel="noreferrer"
        >
          Aura ID Theft Protection
        </a>{' '}
      </span>
    ),
    testimonial:
      'TopicRanker is truly one of a kind, it combines SERP analysis and keyword research into one cohesive output suggesting only keywords which have major weak spots in search results and you can easily rank for. Great platform for anyone doing keyword research.',
  },
  {
    image: '/assets/testimonials/IgalStolpner.png',
    name: 'Igal Stolpner',
    company: (
      <span>
        Founder of{' '}
        <a
          href="https://www.salesmessage.com/"
          target="_blank"
          rel="noreferrer"
        >
          Webify.io
        </a>{' '}
        SEO Agency {`(former VP Growth @ Investing.com)`}
      </span>
    ),
    testimonial:
      "Keyword research didn't change much over the past few years, but Google and the SERPs most definitely did. TopicRanker is probably the only keyword research tool I've stumbled upon that takes keyword research beyond difficulty scores, to focus on actual weak spots on SERPs which until now required manual checks, SERP by SERP.",
  },
  {
    image: '/assets/testimonials/RomeoRazzi.png',
    name: 'Romeo Razzi - CPA',
    company: (
      <span>
        Founder @{' '}
        <a href="https://www.taxedright.com" target="_blank" rel="noreferrer">
          TaxedRight.com
        </a>
      </span>
    ),
    testimonial:
      'TopicRanker has helped me pinpoint specific problems on SERP and why I should write an article on a specific topic. Love the tool, use it on regular basis.',
  },
  {
    image: '/assets/testimonials/ChrisBrisson.png',
    name: 'Chris Brisson',
    company: (
      <span>
        Founder @{' '}
        <a href="https://www.salesmessage.com" target="_blank" rel="noreferrer">
          SalesMessage.com
        </a>
      </span>
    ),
    testimonial:
      'Before TopicRanker we had an issue of randomly writing articles we thought we deserve to rank for without actually looking at the SERP and going after keyword which have specific problems and weaknesses. This tool is a game changer for us.',
  },
  {
    image: '/assets/testimonials/PeterFoster.png',
    name: 'Peter Foster',
    company: (
      <span>
        Business Development @{' '}
        <a href="https://chantblaster.com/" target="_blank" rel="noreferrer">
          ChantBlaster.com
        </a>
      </span>
    ),
    testimonial:
      'TopicRanker is a must have for any business. Everyone has a basic understanding of content marketing, SEO, keywords, but trust me, TopicRanker Plus package with 1:1 consultations is the difference between failure and success. The insights from these calls improved our campaign from a sure cashburner to a well-converting online marketing effort.',
  },
  {
    image: '/assets/testimonials/GaetanoDiNardi.png',
    name: 'Gaetano DiNardi',
    company: (
      <span>
        Growth & SEO Advisor @{' '}
        <a
          href="https://officialgaetano.com/about/"
          target="_blank"
          rel="noreferrer"
        >
          OfficialGaetano.com
        </a>
      </span>
    ),
    testimonial:
      "SEO is so hard now. Everything is hyper competitive. And that's exactly why SEO teams need TopicRanker. To identify long-tail ranking opportunities without spending hours doing manual keyword research.",
  },
  {
    image: '/assets/testimonials/KenSavage.png',
    name: 'Ken Savage',
    company: (
      <span>
        Founder @{' '}
        <a
          href="https://productlaunchpro.com/"
          target="_blank"
          rel="noreferrer"
        >
          ProductLaunchPro.com
        </a>
      </span>
    ),
    testimonial:
      'Finding a keyword which you can easily rank for is not easy these days, all the keyword tools give you a bogus keyword difficulty score which is not helpful. It says “easy” yet when you write an article you don’t easily rank for it. TopicRanker is the only tool I found which actually gets results, it pinpoints real weak spots in SERP to show you exactly why you will rank easily.',
  },
]
