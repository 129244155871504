import styles from './landingPageHero.module.scss'
import { semrushUrl } from '../Header'
import CTASection from '@components/CTASection'
import StarElement from '@components/StarElement'
import Link from 'next/link'

type LandingPageHeroProps = {
  openWistia: () => void
  semrushLanding?: boolean
}

const LandingPageHero = ({
  semrushLanding = false,
  openWistia,
}: LandingPageHeroProps) => {
  const ratesTestimonials = [
    "TopicRanker is a game changer for us, we've never ranked #1 so fast.",
    'TopicRanker takes keyword research to the next level.',
    'Very helpful tool for identifying "hidden gem" easy-to-rank keywords.',
  ]

  return (
    <section className={styles.hero}>
      <div className={styles.heroInner}>
        <div className={styles.heroContent}>
          {semrushLanding ? (
            <>
              <h1 className={styles.heroTitle}>
                Find easy-to-rank keywords based on competitor weak spots in
                SERPs
              </h1>
              <p className={styles.heroText}>
                Lousy Google search results? That's your cue for easy ranking
                potential. We go beyond keyword difficulty metrics, pinpointing
                problematic SERPs ripe for the taking.
              </p>
              <a className={styles.yellowButton} href={semrushUrl}>
                Get Free Trial
              </a>
              <div className={styles.semrushText}>
                on
                <img
                  src={'/assets/landing/semrush_logo_white.png'}
                  className={styles.semrushLogo}
                  alt="Semrush"
                />
                app center
              </div>
            </>
          ) : (
            <>
              <CTASection openWistia={openWistia} />
              <div className={styles.ratesTestimonials}>
                {ratesTestimonials.map((item: string) => (
                  <Link href="#testimonials" passHref>
                    <div className={styles.ratesTestimonial}>
                      <StarElement />
                      <div className={styles.text}>{item}</div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        {/* eslint-disable-next-line @next/next/no-img-element*/}
        <img
          src={'/assets/tr-shapes.svg'}
          className={styles.heroShapes}
          alt="Geometric shapes"
        />

        {/* eslint-disable-next-line @next/next/no-img-element*/}
        <img
          src={'/assets/tr-wave.svg'}
          className={styles.heroWave}
          alt="Wave shape"
        />
      </div>
    </section>
  )
}

export default LandingPageHero
