import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import MediaQuery from 'react-responsive'
import { useSetRecoilState } from 'recoil'
import { NavLink } from '../../interfaces/profile'
import { subscriptionSignUpState } from '../../recoil/atoms'
import Dropdown from '../Dropdown/Dropdown'
import styles from './header.module.scss'
import classNames from 'classnames'
import BrandLogo from '@components/BrandLogo'
import dropdownStyles from '@components/Dropdown/dropdown.module.scss'
import Image from 'next/image'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import ProductHunt from '@components/ProductHunt'

const navLinks: NavLink[] = [
  { title: 'Features', href: '/#features' }, // revert after producthunt finished
  { title: 'Pricing', href: '/#pricing' },
  { title: 'Blog', href: 'https://blog.topicranker.com/' },
  { title: 'About', href: '/about' },
  { title: 'Login', href: '/signin' },
]

type HeaderProps = {
  mode: 'dark' | 'light'
  semrushLanding?: boolean
}

export const semrushUrl = 'https://semrush.sjv.io/c/5184423/1952203/13053'

export const navigateBtn = (
  isAuthenticated: boolean,
  semrushLanding = false
) => {
  if (isAuthenticated) return 'Dashboard'
  if (semrushLanding)
    return (
      <>
        Sign up at{' '}
        <img
          src={'/assets/landing/semrush_logo.png'}
          className={styles.semrushLogo}
          alt="Semrush"
        />
      </>
    )
  return 'Sign up Now'
}

function Header({ mode = 'dark', semrushLanding = false }: HeaderProps) {
  const { status } = useSession()
  const isAuthenticated = status === 'authenticated'
  const router = useRouter()
  const setSubSignUpData = useSetRecoilState(subscriptionSignUpState)

  const loginLinkCheck = (link: string) => {
    return isAuthenticated && link == 'Sign In'
  }

  const navigate = () => {
    let route
    if (isAuthenticated) {
      route = '/dashboard'
    } else if (semrushLanding) {
      route = semrushUrl
    } else {
      route = '/#pricing'
      setSubSignUpData({
        priceId: '',
        customerStripeId: '',
        type: 'STARTER',
        annual: false,
        subscriptionId: '',
      })
    }
    router.push(route)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const signOutUser = () => {
    // setProfile(null)
    signOut({ callbackUrl: '/' })
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const signUpPages = ['/signup', '/checkout'].includes(router.route)

  return (
    <div
      className={classNames(styles.headerActive, {
        [styles.headerContainer]: true,
        [styles.dark]: mode === 'dark',
        [styles.light]: mode === 'light',
      })}
    >
      <BrandLogo semrushLanding={semrushLanding} />

      <MediaQuery minWidth={semrushLanding ? 1330 : 1241}>
        {signUpPages ? (
          router.route === '/checkout' ? (
            <>
              <div
                className={styles.userIcon}
                aria-controls={open ? 'nav-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuClick}
              >
                <Image
                  src={'/assets/user-inverse.svg'}
                  alt="user"
                  height={42}
                  width={42}
                />
              </div>
              <Menu
                autoFocus={false}
                className={styles.navMenu}
                id="nav-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={signOutUser}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <button
              onClick={() => {
                router.back()
              }}
              type="button"
              className={dropdownStyles.hamburger}
            >
              <Image
                src={'/assets/cancel.svg'}
                alt="close"
                width={20}
                height={20}
              />
            </button>
          )
        ) : (
          <nav className={styles.navBar}>
            {navLinks.map((navLink) =>
              !loginLinkCheck(navLink.title) ? (
                <Link key={navLink.title} href={navLink.href} passHref>
                  <a className={styles.navLink}>{navLink.title}</a>
                </Link>
              ) : null
            )}
            <button className={styles.dashboardBtn} onClick={navigate}>
              {navigateBtn(isAuthenticated, semrushLanding)}
            </button>
          </nav>
        )}
      </MediaQuery>
      <MediaQuery maxWidth={semrushLanding ? 1329 : 1240}>
        <Dropdown
          menuIcon={mode == 'dark' ? 'white' : 'black'}
          navLinks={navLinks}
        />
      </MediaQuery>
    </div>
  )
}

export default Header
