import styles from './CTASection.module.scss'
import {
  testimonials,
  TestimonialsProps,
} from '@components/Testimonials/testimonials'
import Link from 'next/link'
import StarElement from '@components/StarElement'

const Rating = () => {
  return (
    <div className={styles.ratingWrapper}>
      <Link href="#testimonials" passHref>
        <div className={styles.images}>
          {testimonials.map((testimonial: TestimonialsProps) => (
            <span className={styles.imageWrapper}>
              <img
                className={styles.roundedImage}
                src={testimonial.image}
                width={34}
                height={34}
              />
            </span>
          ))}
        </div>
      </Link>
      <Link href="#testimonials" passHref>
        <div className={styles.trustedBlock}>
          <StarElement />
          <div>Trusted by 2620+ businesses</div>
        </div>
      </Link>
    </div>
  )
}

export default Rating
