import Head from 'next/head'

type Props = {
  title: string
}

const LandingPageHead = ({ title }: Props) => {
  return (
    <Head>
      <title>{title}</title>
      <meta
        name="description"
        content="TopicRanker topic research tool finds weaknesses and problems in search results and recommends keywords you should target."
      />
    </Head>
  )
}

export default LandingPageHead
