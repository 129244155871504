import styles from './CTASection.module.scss'
import Image from 'next/image'
import Rating from '@components/CTASection/Rating'
import Link from 'next/link'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

type SectionProps = {
  openWistia: () => void
}

const CTASection = ({ openWistia }: SectionProps) => {
  return (
    <section className={styles.hero}>
      <div className={styles.content}>
        <h2 className={styles.mainText}>
          Find easy-to-rank keywords{' '}
          <span className={styles.gradient}>based on weak spots in SERP</span>
        </h2>
        <div className={styles.description}>
          Uncover keywords where the SERP has title mismatches, low word count,
          slow load times, poor readability, low DA sites.
        </div>
        <Rating />
        <div className={styles.buttons}>
          <Link href={'#features'} passHref>
            <button className={styles.whiteBtn}>Learn More</button>
          </Link>
          <Link href={'#pricing'} passHref>
            <button className={styles.gradientBtn}>
              Get Started <ArrowForwardIcon />
            </button>
          </Link>
        </div>
      </div>
      <div className={styles.videoFrame} onClick={openWistia}>
        <Image
          className={styles.videoTeaserImage}
          src="/assets/how-to-teaser.jpg"
          width={1004}
          height={564}
          alt="how to use our platform"
        />

        <div className={styles.videoPlayOverlay}>
          <button
            className={styles.videoPlayButton}
            onClick={openWistia}
            aria-label="Watch Video"
          >
            <Image
              src="/assets/play-icon.svg"
              width={24}
              height={24}
              alt="Play Video"
              onClick={openWistia}
            />
          </button>
        </div>
      </div>
    </section>
  )
}

export default CTASection
