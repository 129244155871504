import React, { useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { NavLink } from '../../interfaces/profile'
import { profileState } from '../../recoil/atoms'
import dropdownStyles from './dropdown.module.scss'
import router from 'next/router'
import { signOut, useSession } from 'next-auth/react'
import { semrushUrl, navigateBtn } from '../Header'
import ProductHunt from '@components/ProductHunt'

type Props = {
  navLinks: NavLink[]
  menuIcon: 'black' | 'white'
  semrushLanding?: boolean
}

export default function Dropdown({
  navLinks,
  menuIcon,
  semrushLanding = false,
}: Props) {
  const setProfile = useSetRecoilState(profileState)
  const [menuClicked, setMenuClicked] = useState<boolean>(false)
  const { status } = useSession()
  const loginLinkCheck = (link: string) => {
    return status === 'unauthenticated' && link == 'Login'
  }

  function menuClicking() {
    setMenuClicked((prev) => !prev)
  }

  const handleSignOut = async () => {
    try {
      await signOut()
      setProfile(null)
      router.push('/')
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (menuClicked) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [menuClicked])

  return (
    <>
      <button
        onClick={() => menuClicking()}
        type="button"
        className={dropdownStyles.hamburger}
      >
        {menuClicked ? (
          <Image
            src={
              menuIcon === 'black'
                ? '/assets/black_cancel.svg'
                : '/assets/cancel.svg'
            }
            alt="menu"
            width={20}
            height={20}
          />
        ) : (
          <Image
            src={
              menuIcon === 'black'
                ? `/assets/black_menu.svg`
                : `/assets/menu.svg`
            }
            alt="menu"
            width={60}
            height={60}
          />
        )}
      </button>

      <nav
        className={
          menuClicked
            ? `${dropdownStyles.navBar} ${dropdownStyles.dropdown}`
            : dropdownStyles.navBar
        }
      >
        {navLinks.map((navLink) =>
          !loginLinkCheck(navLink.title) ? (
            <Link key={navLink.title} href={navLink.href} passHref>
              <a
                className={dropdownStyles.nav_link}
                onClick={() => menuClicking()}
              >
                {navLink.title}
              </a>
            </Link>
          ) : null
        )}
        {navLinks.find((navlink) => navlink.href == '/dashboard') ? (
          <button
            onClick={handleSignOut}
            className={dropdownStyles.signout_btn}
          >
            <Image
              src="/assets/x-circle.svg"
              width={64}
              height={65}
              alt="Sign out"
            ></Image>
          </button>
        ) : (
          <Link
            href={
              status === 'authenticated'
                ? '/dashboard'
                : semrushLanding
                ? semrushUrl
                : '/#pricing'
            }
            passHref
          >
            <a onClick={() => status !== 'authenticated' && menuClicking()}>
              <button className={dropdownStyles.dashboardBtn}>
                {navigateBtn(status === 'authenticated')}
              </button>
            </a>
          </Link>
        )}
      </nav>
    </>
  )
}
