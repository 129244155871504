import Image from 'next/image'
import styles from './whoIsTopicRankerFor.module.scss'
import { useEffect, useRef, useState } from 'react'

const WhoIsTopicRankerFor = () => {
  const [isContainerVisible, setIsContainerVisible] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsContainerVisible(true)
            if (containerRef.current) observer.unobserve(containerRef.current)
          }
        })
      },
      { threshold: 0.3 }
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [])

  return (
    <section
      className={
        isContainerVisible
          ? `${styles.topicRankerFor} ${styles.visible}`
          : styles.topicRankerFor
      }
      ref={containerRef}
    >
      <h2 className={styles.title}>Who is TopicRanker for?</h2>
      <div className={styles.userTypesContainer}>
        <div className={styles.user_type}>
          <Image
            className={styles.round_image}
            alt="seo agencies"
            src="/assets/landing/seo_agencies.png"
            width={365}
            height={365}
          />
          <h3 className={styles.profession}>SEO Agencies</h3>
        </div>
        <div className={styles.user_type}>
          <Image
            className={styles.round_image}
            alt="marketers"
            src="/assets/landing/marketers.png"
            width={365}
            height={365}
          />
          <h3 className={styles.profession}>Marketers</h3>
        </div>
        <div className={styles.user_type}>
          <Image
            className={styles.round_image}
            alt="entrepreneurs"
            src="/assets/landing/entrepreneurs.png"
            width={365}
            height={365}
          />
          <h3 className={styles.profession}>Entrepreneurs</h3>
        </div>
      </div>
    </section>
  )
}

export default WhoIsTopicRankerFor
