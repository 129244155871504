import Image from 'next/image'
import Link from 'next/link'
import styles from './tryOut.module.scss'
import { useEffect, useRef, useState } from 'react'
import { semrushUrl } from '@components/Header'

type Feature = {
  title: string
  icon: any
}

const features: Feature[] = [
  {
    title: 'Keyword Research',
    icon: (
      <Image
        src={'/assets/try_out/magnifying.svg'}
        width={48}
        height={48}
        alt=""
      />
    ),
  },
  {
    title: 'Article Title Generator',
    icon: (
      <Image src={'/assets/try_out/lamp.svg'} width={48} height={48} alt="" />
    ),
  },
  {
    title: 'Content Brief',
    icon: (
      <Image
        src={'/assets/try_out/content.svg'}
        width={48}
        height={48}
        alt=""
      />
    ),
  },
  {
    title: 'Article Writer',
    icon: (
      <Image
        src={'/assets/try_out/article.svg'}
        width={48}
        height={48}
        alt=""
      />
    ),
  },
  {
    title: 'Meta Description Generator',
    icon: (
      <Image
        src={'/assets/try_out/metadescription.svg'}
        width={48}
        height={48}
        alt=""
      />
    ),
  },
]

const TryOut = ({ semrushLanding = false }) => {
  const [isContainerVisible, setIsContainerVisible] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('visible')
            setIsContainerVisible(true)
            if (containerRef.current) observer.unobserve(containerRef.current)
          }
        })
      },
      { threshold: 0.5 }
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [])

  return (
    <section className={styles.container}>
      <div
        className={
          isContainerVisible
            ? `${styles.image} ${styles.visible}`
            : styles.image
        }
        ref={containerRef}
      >
        {/* eslint-disable-next-line @next/next/no-img-element*/}
        <img src={'/assets/wave.svg'} alt="" />
      </div>
      <div className={styles.contentWrapper}>
        <h2>
          <div className={styles.title}>
            Uncover those{' '}
            <span className={styles.blue_text}>easy to rank keywords</span>
          </div>
        </h2>

        <h2>
          <div className={styles.title}>
            Streamline{' '}
            <span className={styles.blue_text}>content creation with AI</span>
          </div>
        </h2>

        <p className={styles.subtitle}>
          Sign up for your free account and find those easy to rank keywords
          today!
        </p>

        <div className={styles.features_wrapper}>
          {features.map((feature, index) => (
            <div className={styles.feature_wrapper} key={index}>
              {feature.icon}
              <div className={styles.feature_title}>{feature.title}</div>
            </div>
          ))}
        </div>

        {semrushLanding ? (
          <div className={styles.semrushWrapper}>
            <Link href={semrushUrl} passHref>
              <button className={styles.get_started_button}>
                <p>Get Free Trial</p>
              </button>
            </Link>
            <span className={styles.semrushText}>
              on
              <img
                src={'/assets/landing/semrush_logo.png'}
                className={styles.semrushLogo}
                alt="Semrush"
              />
              app center
            </span>
          </div>
        ) : (
          <Link href="/#pricing" passHref>
            <button className={styles.get_started_button}>
              <p>Sign up now</p>
            </button>
          </Link>
        )}
      </div>
    </section>
  )
}

export default TryOut
