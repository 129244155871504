import { useEffect, useState } from 'react'
import Header from '@components/Header'

import styles from './landingPageHeader.module.scss'
import classNames from 'classnames'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../recoil/atoms'
import { SUBSCRIPTION_TYPES } from '../../utils/consts'
import Link from 'next/link'

const LandingPageHeader = ({ semrushLanding = false }) => {
  const [isActive, setIsActive] = useState(false)
  const profile = useRecoilValue(profileState)
  const paidUser =
    profile?.subscriptionData?.activePlan !== SUBSCRIPTION_TYPES.FREE.id

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        if (window.scrollY > 80) {
          setIsActive(true)
        } else {
          setIsActive(false)
        }
      },
      { passive: true }
    )

    return () => {
      window.removeEventListener('scroll', () => {})
    }
  }, [])

  return (
    <header
      className={classNames(styles.header, { [styles.headerActive]: isActive })}
    >
      {profile && !paidUser && (
        <div
          className={classNames(styles.headerBanner, {
            [styles.headerBannerActive]: isActive,
          })}
        >
          <p>
            Complete
            <Link href="/settings/subscription" passHref>
              <a>
                <b> your signup </b>
              </a>
            </Link>
            to see the results of the search.
          </p>
        </div>
      )}
      <Header mode="dark" semrushLanding={semrushLanding} />
    </header>
  )
}

export default LandingPageHeader
