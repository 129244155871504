import React from 'react'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Carousel from 'react-elastic-carousel'

import TestimonialCard from '@components/TestimonialCard'
import styles from './Testimonials.module.scss'
import carouselstyles from './Carousel.module.scss'
import { testimonials, TestimonialsProps } from './testimonials'

const Testimonials = ({ id = 'testimonials' }) => {
  const breakPoints = [{ width: 1, itemsToShow: 1.2 }]

  return (
    <section className={styles.container} id={id}>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          See how TopicRanker helps other businesses{' '}
        </div>
        <div className={styles.gradientText}>rank #1 on Google.</div>
      </div>
      {[0, 1, 2, 3].map((rowIndex: number) => (
        <div className={styles.testimonialsContainer}>
          {testimonials
            .slice(rowIndex * 3, (rowIndex + 1) * 3)
            .map((testimonial: TestimonialsProps, index: number) => (
              <TestimonialCard
                key={index + testimonial.name}
                name={testimonial.name}
                company={testimonial.company}
                testimonial={testimonial.testimonial}
              />
            ))}
        </div>
      ))}
      <div className={styles.testimonialsContainerMobile}>
        <Carousel
          breakPoints={breakPoints}
          isRTL={false}
          showArrows={false}
          pagination={false}
          className={styles.carousel}
        >
          {testimonials.map((testimonial: TestimonialsProps, index: number) => (
            <div
              className={carouselstyles.sliderContainer}
              key={index + testimonial.name}
            >
              <TestimonialCard
                key={index + testimonial.name}
                name={testimonial.name}
                company={testimonial.company}
                testimonial={testimonial.testimonial}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default Testimonials
