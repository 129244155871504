import styles from './TestimonialCard.module.scss'

const TestimonialCard = ({
  name,
  company,
  testimonial,
}: {
  name: string
  company: string
  testimonial: string
}) => {
  return (
    <div className={styles.testimonial}>
      <div className={styles.userInfo}>
        <div
          className={`${styles.imageContainer} ${
            styles[name.split(' ')[0].toLocaleLowerCase()]
          }`}
        ></div>
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.company}>{company}</div>
        </div>
      </div>
      <div className={styles.testimonialText}>{testimonial}</div>
    </div>
  )
}

export default TestimonialCard
